import { defineStore } from 'pinia';
import axios from 'axios';

export const useYachtsStore = defineStore('yachts', {
    state: () => ({
        yachts: [],
    }),
    actions: {
        async fetchYachts() {
            let res = await axios.get('yachts');
            console.log('setting yacht state to', res.data);
            this.$state.yachts = res.data;
        },
        clearYachts() {
            this.$reset();
        },
    },
    persist: true,
});
